import React from 'react'
import Title from 'elements/Typography/Title'
import useAuthMutation from 'hooks/useAuthMutation'
import Link from 'elements/Link/Link'
import SuccessMessage from 'elements/Form/SuccessMessage'
import ErrorMessage from 'elements/Form/ErrorMessage'
import FloatingActionButton from 'elements/Button/FloatingActionButton'

import CollectionEditorForm from './CollectionEditorForm'
import { MODULE_LIST_ROUTE } from 'config/routes'
import { ADD_MODULE } from 'config/queries'

function CollectionEditorPage () {
  const moduleListRoute = MODULE_LIST_ROUTE

  const [addModule, { data: mutationData, error: mutationError, loading: mutationLoading }] = useAuthMutation(ADD_MODULE)

  const error = mutationError
  const success = !mutationLoading && mutationData && !mutationError

  const onSubmit = async ({ key, value }) => {
    const input = {
      key,
      value

    }
    addModule({ variables: { input } })
  }

  return (
    <>
      <Title label="Code editor">Code editor</Title>
      <FloatingActionButton icon="arrow-back" tooltip="Go to item list" title="Go to module list" to={moduleListRoute} />
      <CollectionEditorForm onSubmit={onSubmit}/>
      { success ? <SuccessMessage message={<Link to={moduleListRoute}>Click here to go to module list</Link>}/> : null }
      { error ? <ErrorMessage message={error.graphQLErrors[0].message} data={error.graphQLErrors[0].data}/> : null }
    </>
  )
}

CollectionEditorPage.propTypes = {

}

export default CollectionEditorPage
